.footer_base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  background-color: #252525;
  height: 20rem;
}

.footer_base > div {
  margin: 1rem;
}

.footer_links_to_contact {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.footer_links_to_contact > a {
  margin-left: 0.5rem;
}

.footer_link_email {
  border: 0.1rem solid var(--borderColor);
  border-radius: 0.3rem;
  padding: 0.5rem;
  font-size: var(--fontBig);
}

.footer_link_email:hover {
  background-color: var(--secondaryTextColor);
  color: white;
  border-color: var(--secondaryTextColor);
}

.footer_link_tag {
  text-decoration: none;
  color: var(--secondaryTextColor);
}

.footer_link_tag > img {
  width: 2rem;
  color: var(--primaryTextColor);
}
