.about_base {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem;
  margin-top: 4rem;
}

.about_base_left {
  text-align: center;
}

.about_base_left > img {
  width: 40rem;
  max-width: 100%;
  border-radius: 15%;
  filter: drop-shadow(2px 2px 10px var(--primaryTextColor));
}

.about_base_left_bigName {
  font-size: var(--fontBigger);
}

.about_base_left_smallName {
  font-size: var(--fontBig);
  opacity: 0.7;
  margin-top: -2rem;
}

.about_base_left_link_tag {
  text-decoration: none;
  color: var(--secondaryTextColor);
}

.about_base_left_link_tag > img {
  width: 2rem;
  margin-left: 1rem;
  filter: invert(50%);
}

.about_base_left_link_tag > img:hover {
  filter: invert(100%);
}

.about_base_right {
  display: flex;
  flex-direction: column;
  margin-left: 4rem;
}

.about_base_right_title {
  font-size: var(--fontBigger);
  color: var(--secondaryTextColor);
  margin-bottom: 2rem;
}

.about_base_right > p {
  font-size: var(--fontBig);
  opacity: 0.8;
  line-height: 1.6;
  margin-top: 0rem;
}

@media only screen and (max-width: 768px) {
  .about_base {
    flex-direction: column;
  }

  .about_base_right {
    margin-top: 4rem;
    margin-left: 0rem;
  }
}
