.privateProjects_base {
  margin: 2rem;
}

.privateProjects_title {
  margin-top: 4rem;
  margin-bottom: 4rem;
  font-size: var(--fontBigger);
  color: var(--secondaryTextColor);
  text-align: center;
}

.privateProjects_title_tag_up {
  position: relative;
  bottom: -0.5rem;
  left: -7rem;
  opacity: 0.2;
  color: var(--primaryTextColor);
  font-family: monospace;
  line-height: 0%;
  font-weight: 1000;
  z-index: -1;
}

.privateProjects_title_tag_down {
  position: relative;
  bottom: 0.6rem;
  left: 56%;
  opacity: 0.2;
  color: var(--primaryTextColor);
  font-family: monospace;
  line-height: 0%;
  font-weight: 1000;
  max-width: fit-content;
  z-index: -1;
}

.privateProjects_showcase_big {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primaryContainerBackgroundColor);
  border-radius: 1rem;
}

.privateProjects_showcase_big_left > div {
  margin: 1rem;
}

.privateProjects_showcase_big_left_app_name {
  font-size: var(--fontBigger);
  color: var(--secondaryTextColor);
}

.privateProjects_showcase_big_left_tech_tag {
  display: inline-flex;
  flex-direction: row;
  max-width: 100%;
  flex-flow: wrap;
}

.privateProjects_showcase_big_left_tech_tag > div {
  display: inherit;
  align-items: center;
  border: 0.1rem solid var(--borderColor);
  border-radius: 1rem;
  margin: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  font-size: var(--fontSmallest);
}

.privateProjects_showcase_big_left_tech_tag > div > img {
  width: 1.5rem;
}

.privateProjects_showcase_big_left_hr {
  border: 0.1rem dotted var(--secondaryTextColor);
}

.privateProjects_showcase_big_right {
  background-color: aliceblue;
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;
}

.privateProjects_showcase_big_right > img {
  width: 40rem;
  max-width: 100%;
  transition: all 0.25s ease-in 0s;
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.5));
}

.privateProjects_showcase_big_right > img:hover {
  transform: scale(1.03);
}

.privateProjects_showcase_big_left > div > a {
  text-decoration: none;
  color: var(--secondaryTextColor);
}

.privateProjects_showcase_big_medium {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.privateProjects_showcase_big_medium_moreCome {
  background-color: var(--primaryContainerBackgroundColor);
  color: var(--secondaryTextColor);
  border-radius: 1rem;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-left: 2rem;
  transition: all 0.25s ease-in 0s;
  font-size: var(--fontBig);
}

.privateProjects_showcase_big_medium_moreCome:hover {
  transform: scale(1.05);
  filter: drop-shadow(2px 2px 10px var(--primaryTextColor));
}

@media only screen and (max-width: 768px) {
  .privateProjects_showcase_big {
    flex-direction: column;
  }

  .privateProjects_showcase_big_right {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 1rem;
  }

  .privateProjects_showcase_big_medium {
    flex-direction: column;
  }

  .privateProjects_showcase_big_medium_moreCome {
    margin-top: 2rem;
    margin-left: 0rem;
    width: 80%;
  }
}
