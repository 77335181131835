/* html light-dark global variables & values   */
@font-face {
  font-family: robotoFont;
  src: url(./font/Roboto-Regular.ttf);
}

html {
  --primaryBackgroundColor: #f5f6f7;
  --primaryContainerBackgroundColor: #ffffff;
  --primaryTextColor: #424242;
  --secondaryTextColor: #8f0b82; /*ff1493*/
  --fontBig: 1.25rem; /*20px*/
  --fontMedium: 1rem; /*16px*/
  --fontSmall: 0.875rem; /*14px*/
  --borderColor: lightgrey;
  --fontBigger: 2.3rem;
  --fontSmallest: 0.625rem; /*10px*/
  scroll-behavior: smooth;
}

html.dark {
  --primaryBackgroundColor: #3c3c3c;
  --primaryContainerBackgroundColor: #252525;
  --primaryTextColor: #f2f2f2;
  --secondaryTextColor: #9b0c88; /*ff1493*/
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: robotoFont;
  background-color: var(--primaryBackgroundColor);
  color: var(--primaryTextColor);
}
